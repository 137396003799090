<template>
	<div class="policy">
		<tabBar></tabBar>
		<div class="homes">
			<span>{{title}}</span>
		</div>
		<el-row class="conten">
		  <el-col :span="4"><div class="grid-content bg-purple"></div></el-col>
		  <el-col :span="16">
				<div class="contens" v-html="privadata.content"></div>
			</el-col>
		  <el-col :span="4"><div class="grid-content bg-purple"></div></el-col>
		</el-row>
		<footers class="foos"></footers>
	</div>
</template>

<script>
	import tabBar from "@/components/tabBar.vue";
	import triangle from "@/components/triangle.vue";
	import footers from "@/components/footer.vue";
	import { getprivacy } from "@/request/xie";
	export default{
		components:{
			tabBar,
			triangle,
			footers
		},
		data(){
			return{
				privadata:{},
				title:'',
				types:''
			}
		},
		watch:{
			$route:{
				handler(val){
					this.types=val.query.type
					if(this.types=='PRIVACY_SETTING'){
						this.title='隐私政策'
					}else if(this.types=='PRIVACY_SETTING_SIX'){
						this.title='60天保证'
					}else if(this.types=='PRIVACY_SETTING_MANAGER'){
						this.title='隐私管理'
					}else{
						this.title='条款和条件'
					}
					this.getyins()
				},
				deep:true
			}
		},
		mounted() {
			if(this.$route.query.type){
				this.types=this.$route.query.type
				if(this.types=='PRIVACY_SETTING'){
					this.title='隐私政策'
				}else if(this.types=='PRIVACY_SETTING_SIX'){
					this.title='60天保证'
				}else if(this.types=='PRIVACY_SETTING_MANAGER'){
					this.title='隐私管理'
				}else{
					this.title='条款和条件'
				}
			}
			this.getyins()
		},
		methods:{
			async getyins(){
				let res=await getprivacy(this.types)
				this.privadata=JSON.parse(res.result)
				//console.log(this.privadata)
			}
		}
	}
</script>

<style scoped lang="scss">
	.grid-content {
			border-radius: 4px;
			min-height: 36px;
		}
	.policy{
		width: 100%;
		overflow-x: hidden;
		.homes{
			margin-top: 80px;
			width: 100%;
			height: 462px;
			position: relative;
			color: #fff;
			font-size: 36px;
			text-align: center;
			line-height: 462px;
			background-image: url('../../assets/img/xie/tiaok.png');
			background-size: 100% 100%;
			z-index: 1;
		}
		.conten{
			width: 100%;
			padding-top: 42px;
			color: #535353;
			font-size: 16px;
			margin-bottom: 105px;
			.contens{
				position: relative;
				z-index: 1;
				div:first-child{
					font-size: 20px;
					font-weight: bold;
					margin-bottom: 20px;
				}
			}
		}
		.foos::before{
			content: "";
			position: absolute;
			z-index: 0;
			height: 693px;
			bottom: 100%;
			left: 38.8%;
			width: 100%;
			background: linear-gradient(hsla(0,0%,93%,.2),hsla(0,0%,85%,.2));
			transform: skew(-52deg);
		}
	}
</style>
